/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {LoggedStatus, ModuleFE_Account, OnLoginStatusUpdated} from '@nu-art/user-account/frontend';
import {
	ModuleFE_Dialog,
	ModuleFE_SyncManager,
	ModuleFE_Thunderstorm,
	ModuleFE_XHR,
	StorageKey,
	Thunder,
	ThunderDispatcher,
	ToastBuilder,
	TS_Route
} from '@nu-art/thunderstorm/frontend';
import {arrayToMap, Module, Second} from '@nu-art/ts-common';
import {Component_HealthcareSpace, QToastContent, Toast_DependencyResolutionNotification} from '@app/hcs/frontend';
import {Page_DiseaseProfile} from '@app/dp/frontend';
import {Panel_ResourceManager_V1_old} from '@km/managers/ResourceEditor';
import {QuaiIconsType} from '@app/styles';
import {Page_OrganizationEditor} from '@app/org/frontend';
import {Page_ResourceEditor_V2} from '@app/hcs/frontend/ui/Page_ResourceEditor/Page_ResourceEditor_V2';
import {Page_PathwayEditor} from '@app/pathway/frontend';
import {Page_LogTemplateManager} from '@app/advisor-content/frontend/ui';
import {Manager_Permissions} from '@km/managers/Manager_Permissions/Manager_Permissions';
import {Page_OrderEditor} from '@app/pathway/frontend/ui/Page_OrderEditor/Page_OrderEditor';
import {Page_ExpressionManager} from '@app/hcs/frontend/ui/expressions/ui/expression-manager/Page_ExpressionManager';
import {Component_SearchV2} from '../../knowledge-manager/components/search/Component_SearchV2';
import {Route_AdvisorContextEditor} from '@app/advisor-content/frontend/ui/advisor-context-editor/consts';
import {ModuleFE_DefaultPermissions, PermissionKey_FE} from '@nu-art/permissions/frontend/PermissionKey_FE';
import {ArtifactsPipelinePermissionKeysNames} from '@app/shared/permission-manager-ui-permissions';

export interface ToggleSideNavBarStateListener {
	__onToggleSideNavBarState: () => void;
}

export const dispatch_onToggleSideNavBarState = new ThunderDispatcher<ToggleSideNavBarStateListener, '__onToggleSideNavBarState'>('__onToggleSideNavBarState');

export type RouteDomainDeclaration = {
	route: TS_Route
	icon: keyof QuaiIconsType; // 'pathway'
	routeTitle: string // 'Pathway Manager'
	designator?: string; // 'V2'
}
const RouteDomainsArray: RouteDomainDeclaration[] = [
	{
		route: Component_SearchV2.Route,
		icon: 'search',
		routeTitle: 'Search',
	},
	{
		icon: 'healthcareSpace',
		route: Component_HealthcareSpace.Route,
		routeTitle: 'Healthcare Space',
	},
	{
		route: Page_DiseaseProfile.Route,
		icon: 'diseaseProfile',
		routeTitle: 'Disease Profile',
	},
	{
		route: Page_PathwayEditor.Route,
		icon: 'pathways',
		routeTitle: 'Pathway Manager',
	},
	{
		route: Page_LogTemplateManager.Route,
		icon: 'fillForm',
		routeTitle: 'Log Template Manager',
	},
	{
		route: Page_ExpressionManager.Route,
		icon: 'expressions',
		routeTitle: 'Expression Manager',
	},
	{
		route: Panel_ResourceManager_V1_old.Route,
		icon: 'resources',
		routeTitle: 'Resource Manager',
		designator: 'RES',
	},
	{
		route: Page_ResourceEditor_V2.Route,
		icon: 'resources',
		routeTitle: 'Resource Manager V2',
		designator: 'v2',
	},
	{
		route: Page_OrganizationEditor.Route,
		icon: 'organization',
		routeTitle: 'Organization Manager V2',
		designator: 'v2',
	},
	{
		route: Page_OrderEditor.Route,
		icon: 'resources',
		routeTitle: 'Order Manager',
	},
	{
		route: Manager_Permissions.Route,
		icon: 'userPermissions',
		routeTitle: 'Permissions Manager',
	},
	{
		route: Route_AdvisorContextEditor,
		routeTitle: 'Advisor Context Manager V2',
		icon: 'newAdvisorForm',
		designator: 'V2',
	}
];
export const RouteDomainsMap = arrayToMap(RouteDomainsArray, item => item.route.key);

export class ModuleFE_AppModule_Class
	extends Module
	implements OnLoginStatusUpdated {

	readonly permissions: ModuleFE_DefaultPermissions<typeof ArtifactsPipelinePermissionKeysNames, {}>;

	async __onLoginStatusUpdated() {
		if (ModuleFE_Account.isStatus(LoggedStatus.LOGGED_IN)) {
			return ModuleFE_SyncManager.startListening();
		} else if (ModuleFE_Account.isStatus(LoggedStatus.LOGGED_OUT)) {
			ModuleFE_SyncManager.stopListening();
			ModuleFE_Dialog.close();
			await ModuleFE_Thunderstorm.clearWebsiteData();
		}
	}


	constructor() {
		super();
		this.permissions = {
			ui: PermissionKey_FE.generatePermissionKeysByLevels(ArtifactsPipelinePermissionKeysNames),
			collection: {}
		};
	}

//NavBar Related Things
	public getNavBarStatus(): boolean {
		return this.navBarStatus.get(false);
	}

	private navBarStatus: StorageKey<boolean> = new StorageKey<boolean>('nav-bar__is-open');

	protected init(): void {
		this.navBarStatus.set(false);

		ModuleFE_XHR.setDefaultOnError(async (errorResponse, input, request) => {
			if (errorResponse.errorResponse?.error?.type === 'has-dependencies') {
				new ToastBuilder()
					.setDuration(6 * Second)
					.setContent(QToastContent({
						iconKey: 'x',
						content: Toast_DependencyResolutionNotification({errorData: errorResponse.errorResponse.error.data}),
						toastType: 'error'
					})).show();
			}
		});
	}

	toggleSideNavBar() {
		const lastNavBarStatus = this.navBarStatus.get();
		this.navBarStatus.set(!lastNavBarStatus);
		dispatch_onToggleSideNavBarState.dispatchUI();
	}

	getEnv() {
		return Thunder.getInstance().getConfig().label; // LOCAL/DEV/STAGING/ empty string for prod
	}
}

export const ModuleFE_AppModule = new ModuleFE_AppModule_Class();
